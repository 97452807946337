<template>
    <div class="loading-page" v-if="authIsLoading">
        <base-spinner></base-spinner>
    </div>

    <div @click="closeForm" class="auth" v-else>
        <div class="row">
            <div class="gr-24">
                <div class="auth-form gr-12 gr-16@small gr-18@tablet gr-24@mobile push-6 push-4@small push-3@tablet push-0@mobile">
                    <form class="form" id="auth-form" @click.stop @submit.prevent="validateData">
                        <div class="form-close" @click="closeForm"></div>
                        <div class="form-content">
                            <div>
                                <h3 @click="switchAuthMode('login')" class="gr-14 gr-18small gr-22@mini push-1"
                                    :class="{ 'back-button': auth === 'forgot' }">
                                    {{ auth === 'login'? 'Login' : ( auth === 'reset'? 'Passwort zurücksetzen' : '') }}
                                </h3>

                                <div class="auth-component">
                                    <keep-alive>
                                        <component :is="visibleComponent" :user="user" :validity="validity"
                                                   @switch-auth-mode="switchAuthMode" @save-user="saveUser"></component>
                                    </keep-alive>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import { defineAsyncComponent } from 'vue';
    const UserLogin = defineAsyncComponent(() => import('./UserLogin.vue'));
    const ResetPassword = defineAsyncComponent(()  => import('./ResetPassword.vue'));
    const ForgotPassword = defineAsyncComponent(()  => import('./ForgotPassword.vue'));

    export default {
        components: {
            UserLogin,
            ResetPassword,
            ForgotPassword,
        },
        props: ['authMode', 'authModeAddition', 'authData', 'authIsLoading'],
        inject: ['loadPage', 'setAuthLoading', 'closeForm', 'setError', 'setNotice', 'setConfirmationNotice', 'checkUserPurchases'],
        data() {
            return {
                auth: '',
                user: {
                    email: '',
                    password: '',
                    confirmPassword: '',
                },
                validity: {
                    email: true,
                    password: true,
                    confirmPassword: true
                },
                error: null,
            }
        },
        created() {
            this.auth = this.authMode;

            if(this.authModeAddition.data && this.authModeAddition.data.user ) {
                this.user = this.authModeAddition.data.user;
            }
        },
        methods: {
            switchAuthMode(mode) {
                this.auth = mode;
            },
            saveUser(user) {
                this.user = user;
            },
            validateData() {
                if(this.auth === 'login') {
                    if(!/\S+@\S+\.\S+/.test(this.user.email)) {
                        this.validity.email = false;
                    } else {
                        this.validity.email = true;
                    }

                    if(this.user.password.length === 0) {
                        this.validity.password = false;
                    } else {
                        this.validity.password = true;
                    }

                    if(this.validity.email && this.validity.password) {
                        this.submit('login');
                    }
                } else if(this.auth === 'reset') {
                    if (this.user.password.length < 8) {
                        this.validity.password = false;
                    } else {
                        this.validity.password = true;
                        this.submit('reset');
                    }
                } else if(this.auth === 'forgot') {
                    console.log(this.user);
                    if(!/\S+@\S+\.\S+/.test(this.user.email)) {
                        this.validity.email = false;
                    } else {
                        this.validity.email = true;
                        this.submit('forgot');
                    }
                }
            },
            async submit(mode) {

                await this.setAuthLoading(true);

                if(mode === 'reset') {

                    await this.$store.dispatch('auth/resetPassword', {
                        jwt: this.authData.jwt,
                        newPassword: this.user.password
                    });

                    await this.setNotice('Dein Passwort wurde zurückgesetzt.');

                } else if(mode === 'forgot') {
                    this.setAuthLoading(true);

                    await this.$store.dispatch('auth/forgotPassword', {
                        email: this.user.email
                    });

                    const errorMessage = await this.$store.getters['auth/getErrorMessage'];

                    this.setAuthLoading(false);

                    if(errorMessage) {
                        this.setError(errorMessage);
                        this.$store.dispatch('auth/clearErrorMessage');
                    } else {
                        await this.setNotice('Wir haben dir eine Mail gesendet, bitte bestätige deine Identität über Klick auf den darin enthaltenen Link.');
                    }

                } else {

                    try {
                        await this.$store.dispatch('auth/' + mode, {
                            user: this.user,
                        })

                        const errorMessage = await this.$store.getters['auth/getErrorMessage'];

                        await this.loadPage();

                        if(errorMessage) {
                            await this.setNotice(errorMessage, 'OK', mode);
                            this.$store.dispatch('auth/clearErrorMessage');

                        } else if(this.authModeAddition.reference) {

                            // auth during order
                            if(this.authModeAddition.reference === 'order') {
                                let product = this.authModeAddition.data.product;
                                let newsletter = this.authModeAddition.data.newsletter;

                                await this.loadPage();
                                await this.checkUserPurchases(product, newsletter);
                            }

                            if(this.authModeAddition.reference === 'first-login') {
                                let greeting = this.user && this.user.firstName ? 'Hallo, ' + this.user.firstName + '!' : 'Hallo!';
                                let text = greeting + ' Möchtest du dein Passwort jetzt zurücksetzen?';

                                await this.$router.replace({ name: 'portal' });
                                setTimeout(this.setConfirmationNotice(text, 'first-login', 'Ja', 'Später'), 5000);
                            }

                        } else {
                            // usual login
                            await this.loadPage();
                            await this.$router.replace({ name: 'portal' });
                        }
                    }
                    catch (err) {
                        const error = err.message || 'Authentifizierung fehlgeschlagen. Bitte versuchen Sie es erneut.';
                        throw error;
                    }
                }

                await this.closeForm();
                await this.setAuthLoading(false);
            },
        },
        computed: {
            visibleComponent() {
                if(this.auth == 'reset') {
                    return ResetPassword;
                } else if(this.auth == 'forgot') {
                    return ForgotPassword;
                } else {
                    return UserLogin;
                }
            },
        }
    }
</script>


<style scoped>
</style>
